import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentOrgUser } from "redux/slices/orgUser";
import { Redirect, useLocation } from "react-router-dom";
import LoadingScreen from "components/feedback/LoadingScreen";
import { PATH_PAGE, PATH_APP } from "routes/paths";

function CurrentOrgUserGuard({ children }) {
  console.log("CurrentOrgUserGuard exewcuted");
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoading, errors, currentOrgUser } = useSelector(
    (state) => state.orgUser
  );

  useEffect(() => {
    dispatch(getCurrentOrgUser());
  }, [dispatch]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (errors || !currentOrgUser || !currentOrgUser.id) {
    console.log("ERROR occurred in CurrentOrgUserGuard: ", errors);
    return <Redirect to={PATH_PAGE.error500} />;
  }

  if (
    (!currentOrgUser.organization || !currentOrgUser.organization.id) &&
    currentOrgUser.role != 0
  ) {
    console.log(
      "ERROR occurred in CurrentOrgUserGuard: user wasn't admin and didn't have org id "
    );
    return <Redirect to={PATH_PAGE.error500} />;
  }

  if (
    currentOrgUser.role == 0 &&
    (!currentOrgUser.organization || !currentOrgUser.organization.id)
  ) {
    if (location.pathname != PATH_APP.org.setup) {
      return <Redirect to={PATH_APP.org.setup} />;
    } else {
      return children;
    }
  }

  return children;
}

export default CurrentOrgUserGuard;
