import React from "react";
import { PATH_APP } from "routes/paths";

import { Icon } from "@chakra-ui/react";
import { MdHome, MdOutlineShoppingCart } from "react-icons/md";

export const pageHeaderName = {
  [PATH_APP.main.dashboard]: "Dashboards",
  [PATH_APP.settings.root]: "Settings",
  [PATH_APP.planning.root]: "Planning",
  [PATH_APP.product.root]: "Product Overview",
  [PATH_APP.product.productDetails]: "Product Details",
  [PATH_APP.product.productSuppliedDetails]: "Product Supplied Detail",
  [PATH_APP.suppliers.root]: "Suppliers Overview",
  [PATH_APP.suppliers.suppliers]: "Suppliers Overview",
  [PATH_APP.suppliers.supplierDetails]: "Suppliers Details",
  [PATH_APP.customers.root]: "Customers Overview",
  [PATH_APP.customers.customerDetails]: "Customer Details",
  [PATH_APP.billOfMaterials.root]: "BOM Overview",
  [PATH_APP.billOfMaterials.billOfMaterials]: "BOM Overview",
  [PATH_APP.billOfMaterials.billOfMaterialsDetails]: "BOM Details",
  [PATH_APP.equipments.root]: "Equipments Overview",
  [PATH_APP.equipments.equipments]: "Equipments Overview",
  [PATH_APP.equipments.equipmentDetails]: "Equipment Details",
  [PATH_APP.purchasing.root]: "Purchasing",
  [PATH_APP.receipts.root]: "Receipts",
  [PATH_APP.manufacturing.root]: "Manufacturing Order Overview",
  [PATH_APP.purchasing.purchaseOrderDetails]: "",
  [PATH_APP.shipment.root]: "Shipment Overview",
  [PATH_APP.inventory.root]: "Inventory Overview",
};

const routes = [
  // --- Dashboards ---
  // {
  //   name: "Dashboards",
  //   path: "dashboard",
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   collapse: false,

  // },
  {
    path: "planning",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Planning",
  },
  {
    path: "products",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Products",
  },
  {
    path: "suppliers",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Suppliers",
  },
  {
    path: "customers",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Customers",
  },
  {
    path: "billofmaterials",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Bill Of Materials",
  },
  {
    path: "equipments",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Equipment List",
  },
  {
    path: "purchasing",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Purchasing",
  },
  {
    path: "receipts",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Receipts",
  },
  {
    path: "inventory",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Inventory",
  },
  {
    path: "manufacturingorder",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Manufacturing",
  },
  {
    path: "shipment",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Shipment",
  },
  {
    path: "settings",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    name: "Settings",
  },

  // {
  //   path: "billofmaterials",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //  // collapse: false,
  //   name: "Bill Of Materials",
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Bill Of Materials",
  //       path: "billofmaterials",
  //       secondary: true,
  //     },
  //     {
  //       name: "Equipment List",
  //       path: "equipments",
  //       secondary: true,
  //     },
  //   ],
  // },
  // --- Suppliers ---
  // {
  //   name: "Suppliers",
  //   path: "suppliers",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Suppliers",
  //       layout: "/",
  //       path: "suppliers/list",
  //       component: NFTMarketplace,
  //       secondary: true,
  //     },
  //     {
  //       name: "Manufacturers",
  //       layout: "/",
  //       path: "manufacturers/list",
  //       component: NFTMarketplace,
  //       secondary: true,
  //     },
  //     // {
  //     //   name: "Collection",
  //     //   layout: "/admin",
  //     //   path: "/nfts/collection",
  //     //   component: NFTCollection,
  //     //   secondary: true,
  //     // },
  //     // {
  //     //   name: "NFT Page",
  //     //   layout: "/admin",
  //     //   path: "/nfts/page",
  //     //   component: NFTPage,
  //     //   secondary: true,
  //     // },
  //     // {
  //     //   name: "Profile",
  //     //   layout: "/admin",
  //     //   path: "/nfts/profile",
  //     //   component: NFTProfile,
  //     //   secondary: true,
  //     // },
  //   ],
  // },
  // {
  //   path: "customers",
  //   icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
  //   collapse: false,
  //   component: DashboardsDefault,
  //   name: "Customers",
  //   layout: "/",
  // },
  // {
  //   path: "billofmaterials",
  //   icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
  //   collapse: false,
  //   component: DashboardsDefault,
  //   name: "Bill Of Materials",
  //   layout: "/",
  // },
  // // --- Main pages ---
  // {
  //   name: "Main Pages",
  //   path: "/main",
  //   icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Account",
  //       path: "/main/account",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Billing",
  //           layout: "/admin",
  //           path: "/main/account/billing",
  //           exact: false,
  //           component: AccountBilling,
  //         },
  //         {
  //           name: "Application",
  //           layout: "/admin",
  //           path: "/main/account/application",
  //           exact: false,
  //           component: AccountApplications,
  //         },
  //         {
  //           name: "Invoice",
  //           layout: "/admin",
  //           path: "/main/account/invoice",
  //           exact: false,
  //           component: AccountInvoice,
  //         },
  //         {
  //           name: "Settings",
  //           layout: "/admin",
  //           path: "/main/account/settings",
  //           exact: false,
  //           component: AccountSettings,
  //         },
  //         {
  //           name: "All Courses",
  //           layout: "/admin",
  //           path: "/main/account/all-courses",
  //           exact: false,
  //           component: AccountAllCourses,
  //         },
  //         {
  //           name: "Course Page",
  //           layout: "/admin",
  //           path: "/main/account/course-page",
  //           exact: false,
  //           component: AccountCoursePage,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Ecommerce",
  //       path: "/main/users",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "New Product",
  //           layout: "/admin",
  //           path: "/main/ecommerce/new-prodcut",
  //           exact: false,
  //           component: EcommerceNewProduct,
  //         },
  //         {
  //           name: "Product Settings",
  //           layout: "/admin",
  //           path: "/main/ecommerce/settings",
  //           exact: false,
  //           component: EcommerceProductSettings,
  //         },
  //         {
  //           name: "Product Page",
  //           layout: "/admin",
  //           path: "/main/ecommerce/page-example",
  //           exact: false,
  //           component: EcommerceProductPage,
  //         },
  //         {
  //           name: "Order List",
  //           layout: "/admin",
  //           path: "/main/ecommerce/order-list",
  //           exact: false,
  //           component: EcommerceOrderList,
  //         },
  //         {
  //           name: "Order Details",
  //           layout: "/admin",
  //           path: "/main/ecommerce/order-details",
  //           exact: false,
  //           component: EcommerceOrderDetails,
  //         },
  //         {
  //           name: "Referrals",
  //           layout: "/admin",
  //           path: "/main/ecommerce/referrals",
  //           exact: false,
  //           component: EcommerceReferrals,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       path: "/main/users",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "New User",
  //           layout: "/admin",
  //           path: "/main/users/new-user",
  //           exact: false,
  //           component: UserNew,
  //         },
  //         {
  //           name: "Users Overview",
  //           layout: "/admin",
  //           path: "/main/users/users-overview",
  //           exact: false,
  //           component: UsersOverview,
  //         },
  //         {
  //           name: "Users Reports",
  //           layout: "/admin",
  //           path: "/main/users/users-reports",
  //           exact: false,
  //           component: UsersReports,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Applications",
  //       path: "/main/applications",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Kanban",
  //           layout: "/admin",
  //           path: "/main/applications/kanban",
  //           exact: false,
  //           component: ApplicationsKanban,
  //         },
  //         {
  //           name: "Data Tables",
  //           layout: "/admin",
  //           path: "/main/applications/data-tables",
  //           exact: false,
  //           component: ApplicationsDataTables,
  //         },
  //         {
  //           name: "Calendar",
  //           layout: "/admin",
  //           path: "/main/applications/calendar",
  //           exact: false,
  //           component: ApplicationsCalendar,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Profile",
  //       path: "/main/profile",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Profile Overview",
  //           layout: "/admin",
  //           path: "/main/profile/overview",
  //           exact: false,
  //           component: ProfileOverview,
  //         },
  //         {
  //           name: "Profile Settings",
  //           layout: "/admin",
  //           path: "/main/profile/settings",
  //           exact: false,
  //           component: ProfileSettings,
  //         },
  //         {
  //           name: "News Feed",
  //           layout: "/admin",
  //           path: "/main/profile/newsfeed",
  //           exact: false,
  //           component: ProfileNewsfeed,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Others",
  //       path: "/main/others",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Notifications",
  //           layout: "/admin",
  //           path: "/main/others/notifications",
  //           exact: false,
  //           component: OthersNotifications,
  //         },
  //         {
  //           name: "Pricing",
  //           layout: "/auth",
  //           path: "/main/others/pricing",
  //           exact: false,
  //           component: OthersPricing,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // --- Authentication ---
  // {
  //   name: "Authentication",
  //   path: "/auth",
  //   icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
  //   collapse: true,
  //   items: [
  //     // --- Sign In ---
  //     {
  //       name: "Sign In",
  //       path: "/sign-in",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Default",
  //           layout: "/auth",
  //           path: "/sign-in/default",
  //           icon: (
  //             <Icon as={MdHome} width='16px' height='16px' color='inherit' />
  //           ),
  //           component: SignInDefault,
  //         },
  //         {
  //           name: "Centered",
  //           layout: "/auth",
  //           path: "/sign-in/centered",
  //           icon: (
  //             <Icon as={MdHome} width='16px' height='16px' color='inherit' />
  //           ),
  //           component: SignInCentered,
  //         },
  //       ],
  //     },
  //     // --- Sign Up ---
  //     {
  //       name: "Sign Up",
  //       path: "/sign-up",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Default",
  //           layout: "/auth",
  //           path: "/sign-up/default",
  //           icon: (
  //             <Icon as={MdHome} width='16px' height='16px' color='inherit' />
  //           ),
  //           component: SignUpDefault,
  //         },
  //         {
  //           name: "Centered",
  //           layout: "/auth",
  //           path: "/sign-up/centered",
  //           icon: (
  //             <Icon as={MdHome} width='16px' height='16px' color='inherit' />
  //           ),
  //           component: SignUpCentered,
  //         },
  //       ],
  //     },
  //     // --- Verification ---
  //     {
  //       name: "Verification",
  //       path: "/verification",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Default",
  //           layout: "/auth",
  //           path: "/verification/default",
  //           icon: (
  //             <Icon as={MdHome} width='16px' height='16px' color='inherit' />
  //           ),
  //           component: VerificationDefault,
  //         },
  //         {
  //           name: "Centered",
  //           layout: "/auth",
  //           path: "/verification/centered",
  //           icon: (
  //             <Icon as={MdHome} width='16px' height='16px' color='inherit' />
  //           ),
  //           component: VerificationCentered,
  //         },
  //       ],
  //     },
  //     // --- Lock ---
  //     {
  //       name: "Lock",
  //       path: "/lock",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Default",
  //           layout: "/auth",
  //           path: "/lock/default",
  //           icon: (
  //             <Icon as={MdHome} width='16px' height='16px' color='inherit' />
  //           ),
  //           component: LockDefault,
  //         },
  //         {
  //           name: "Centered",
  //           layout: "/auth",
  //           path: "/lock/centered",
  //           icon: (
  //             <Icon as={MdHome} width='16px' height='16px' color='inherit' />
  //           ),
  //           component: LockCentered,
  //         },
  //       ],
  //     },
  //     // --- Forgot Password ---
  //     {
  //       name: "Forgot Password",
  //       path: "/forgot-password",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Default",
  //           layout: "/auth",
  //           path: "/forgot-password/default",
  //           icon: (
  //             <Icon as={MdHome} width='16px' height='16px' color='inherit' />
  //           ),
  //           component: ForgotPasswordDefault,
  //         },
  //         {
  //           name: "Centered",
  //           layout: "/auth",
  //           path: "/forgot-password/centered",
  //           icon: (
  //             <Icon as={MdHome} width='16px' height='16px' color='inherit' />
  //           ),
  //           component: ForgotPasswordCentered,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default routes;
