import React from "react";

// Chakra imports
import { Flex, Image, useColorMode } from "@chakra-ui/react";

import { HSeparator } from "components/separator/Separator";
import logoOne from "assets/img/brand/LogoOne.png";
import LogoOneDarkMode from "assets/img/brand/LogoOneDarkMode.png";

export function SidebarBrand() {
  //   Chakra color mode
  const { colorMode } = useColorMode();
  // let logoColor = useColorModeValue("navy.700", "white");
  // let mainText = useColorModeValue("navy.700", "white");

  return (
    <Flex w="285px" align="center" direction="column">
      {/* <Heading mt="20px" as="h3" size="lg" color={mainText}> */}
      {/* HikeMRP */}
      <Image
        src={colorMode === "dark" ? LogoOneDarkMode : logoOne}
        mt="35px"
        w="50%"
        h="100%"
      />
      {/* </Heading> */}
      <HSeparator mb="40px" mt="41px" />
    </Flex>
  );
}

export default SidebarBrand;
