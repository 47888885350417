import React from "react";
import "assets/css/App.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";

import routes, { Routes } from "./routes/Routes";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import FirebaseProvider from "components/auth/FirebaseProvider";
import { ApolloProvider } from "@apollo/client";
import client from "common/graphql/MyApolloClient";

const history = createBrowserHistory();

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router history={history}>
        <FirebaseProvider>
          <ApolloProvider client={client}>
            <Routes routes={routes} />
          </ApolloProvider>
        </FirebaseProvider>
      </Router>
    </ChakraProvider>
  );
}

export default App;
