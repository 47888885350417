import { useMutation, gql } from "@apollo/client";

const mutation = gql`
  mutation createUserAppFeedback(
    $submitterFullName: String!
    $orgName: String!
    $message: String!
  ) {
    createUserAppFeedback(
      submitterFullName: $submitterFullName
      orgName: $orgName
      message: $message
    ) {
      status
    }
  }
`;

export default () => {
  const [createUserAppFeedbackMutation, { data, error }] =
    useMutation(mutation);

  return { createUserAppFeedbackMutation, data, error };
};
