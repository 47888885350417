import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  FormControl,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { MdClose } from "react-icons/md";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useCreateUserAppFeedback } from "common/graphql/userAppFeedback";
import { IoIosChatboxes } from "react-icons/io";
import TextField from "components/fields/TextField";
import { useSelector } from "react-redux";

export default function (props) {
  const { ...rest } = props;
  const toast = useToast();
  const { createUserAppFeedbackMutation } = useCreateUserAppFeedback();
  const { currentOrgUser } = useSelector((state) => state.orgUser);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const navbarIcon = useColorModeValue("gray.400", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );

  const FormSchema = Yup.object().shape({
    message: Yup.string()
      .required("Mesasge is required")
      .max(1000, "Must be at most 1000 characters"),
  });

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: FormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("CLICKED SUMBIT", values);
      setSubmitting(true);

      const variables = {
        message: values.message,
        orgName: currentOrgUser.organization.name,
        submitterFullName: `${currentOrgUser.firstName} ${currentOrgUser.lastName}`,
      };
      await createUserAppFeedbackMutation({ variables: variables })
        .then(async (data) => {
          console.log("mutation data: ", data.data);
          if (data.data.createUserAppFeedback.status) {
            onCloseModal();

            setSubmitting(false);
            toast({
              title: `Thank you! Your feedback has been submitted!`,
              status: "success",
              isClosable: true,
            });
          } else {
            console.log("FAILED");
            toast({
              title: `Sorry, something unexpected happeend when submitting your feedback. Please try again.`,
              status: "error",
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setSubmitting(false);
          console.error("MYERRORRR2:" + err);
          toast({
            title: `Sorry, something unexpected happeend when submitting your feedback. Please try again.`,
            status: "error",
            isClosable: true,
          });
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
    submitForm,
  } = formik;

  const onCloseModal = () => {
    onClose();
    resetForm();
  };

  return (
    <>
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        ml="10px"
        mr="10px"
        onClick={onOpen}
      >
        <Icon
          me="10px"
          h="23px"
          w="23px"
          color={navbarIcon}
          as={IoIosChatboxes}
        />
      </Button>
      <Modal
        isCentered={true}
        closeOnOverlayClick={false}
        size={"2xl"}
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent mx="8px" bg="transparent" boxShadow="unset">
          <ModalBody p="0px" bg="transparent">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <FormControl>
                  <Card mb="20px" {...rest}>
                    <Flex
                      align={{ sm: "flex-start", lg: "flex-start" }}
                      justify={{ sm: "space-between", lg: "space-between" }}
                      w="100%"
                    >
                      <Flex direction="column" mb="30px" ms="10px">
                        <Text
                          fontSize="2xl"
                          color={textColorPrimary}
                          fontWeight="bold"
                        >
                          Submit feedback
                        </Text>
                        <Text mt="1px" color={textColorSecondary} fontSize="md">
                          Are we missing something you need? Is there a bug 😱?
                          Tell us about it!
                        </Text>
                      </Flex>
                      <Button
                        align="center"
                        justifyContent="center"
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w="37px"
                        h="37px"
                        lineHeight="100%"
                        borderRadius="10px"
                        onClick={onCloseModal}
                        // {...rest}
                      >
                        <Icon
                          as={MdClose}
                          color={iconColor}
                          w="24px"
                          h="24px"
                        />
                      </Button>
                    </Flex>
                    <TextField
                      id="message"
                      label="What are we missing?"
                      h="200px"
                      maxh="200px"
                      placeholder="Explain as much as you can! Why do you need it? What were you trying to achieve when you realized you needed this functionality? How frequently would you use it?"
                      errorMessage={touched.message && errors.message}
                      {...getFieldProps("message")}
                    />

                    <Button
                      borderRadius="16px"
                      minW="100px"
                      h="44px"
                      ms="auto"
                      mt="33px"
                      variant="brand"
                      color="white"
                      fontSize="sm"
                      fontWeight="500"
                      _hover={{ bg: "brand.600" }}
                      _active={{ bg: "brand.500" }}
                      _focus={{ bg: "brand.500" }}
                      isLoading={isSubmitting}
                      onClick={submitForm}
                    >
                      Send
                    </Button>
                  </Card>
                </FormControl>
              </Form>
            </FormikProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
