import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  shouldRefetch: false,
};

const slice = createSlice({
  name: 'productSuppliedRefetch',
  initialState,
  reducers: {
    refetch(state) {
      state.shouldRefetch = true;
    },
    refetched(state) {
      state.shouldRefetch = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  refetch,
  refetched
} = slice.actions;

