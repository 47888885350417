function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: "/admin",
  app: "/",
  docs: "/docs",
  setup: "/setup",
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: "/login",
    register: path(ROOTS.auth, "/register"),
    registerUnprotected: path(ROOTS.auth, "/register-unprotected"),
    loginUnprotected: path(ROOTS.auth, "/login-unprotected"),
    resetPassword: path(ROOTS.auth, "/reset-password"),
  },
  error404: "/404",
  error500: "/500",
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
};

export const PATH_HOME = {
  components: "/components",
  cloud: "https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0",
  purchase: "https://material-ui.com/store/items/minimal-dashboard/",
  dashboard: ROOTS.app,
};

export const PATH_APP = {
  root: ROOTS.app,
  org: {
    setup: path(ROOTS.app, "organization-setup"),
  },
  main: {
    root: path(ROOTS.app, "dashboard"),
    dashboard: path(ROOTS.app, "dashboard"),
    ecommerce: path(ROOTS.app, "dashboard/ecommerce"),
    analytics: path(ROOTS.app, "dashboard/analytics"),
  },
  product: {
    root: path(ROOTS.app, "products"),
    productDetails: path(ROOTS.app, "products/details"),
    productSuppliedDetails: path(ROOTS.app, "products/product-supplied-detail"),
  },
  settings: {
    root: path(ROOTS.app, "settings"),
  },
  planning: {
    root: path(ROOTS.app, "planning"),
  },
  purchasing: {
    root: path(ROOTS.app, "purchasing"),
    purchaseOrders: path(ROOTS.app, "purchasing"),
    purchaseOrderDetails: path(ROOTS.app, "purchasing/purchaseorders/details"),
  },
  receipts: {
    root: path(ROOTS.app, "receipts"),
  },
  suppliers: {
    root: path(ROOTS.app, "suppliers"),
    suppliers: path(ROOTS.app, "suppliers"),
    supplierDetails: path(ROOTS.app, "suppliers/details"),
  },
  customers: {
    root: path(ROOTS.app, "customers"),
    customerDetails: path(ROOTS.app, "customers/details"),
  },
  billOfMaterials: {
    root: path(ROOTS.app, "billofmaterials"),
    billOfMaterials: path(ROOTS.app, "billofmaterials"),
    billOfMaterialsDetails: path(ROOTS.app, "billofmaterials/details"),
  },
  equipments: {
    root: path(ROOTS.app, "equipments"),
    equipments: path(ROOTS.app, "equipments"),
    equipmentDetails: path(ROOTS.app, "equipments/details"),
  },
  manufacturing: {
    root: path(ROOTS.app, "manufacturingorder"),
    manufacturingOrderDetails: path(ROOTS.app, "manufacturingorder/details"),
  },
  shipment: {
    root: path(ROOTS.app, "shipment"),
  },
  inventory: {
    root: path(ROOTS.app, "inventory"),
  },
};
