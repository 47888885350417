// Chakra imports
import {
  Flex,
  FormLabel,
  Textarea,
  Text,
  useColorModeValue,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
// Custom components
import React from "react";

export default function Default(props) {
  const { mb, id, label, extra, placeholder, errorMessage, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const bgPrimary = useColorModeValue("transparent", "navy.800");
  const borderPrimary = useColorModeValue(
    "secondaryGray.100",
    "whiteAlpha.100"
  );
  return (
    <Flex direction="column" mb={mb ? mb : "30px"}>
      <FormControl isInvalid={errorMessage != null && errorMessage != ""}>
        <FormLabel
          display="flex"
          ms="10px"
          htmlFor={id}
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="bold"
          _hover={{ cursor: "pointer" }}
        >
          {label}
          <Text fontSize="sm" fontWeight="normal" ms="2px">
            {extra}
          </Text>
        </FormLabel>
        <Textarea
          id={id}
          placeholder={placeholder}
          h="44px"
          maxh="44px"
          color={textColorPrimary}
          fontSize="sm"
          bg={bgPrimary}
          border="1px solid "
          borderColor={borderPrimary}
          borderRadius="16px"
          {...rest}
          _placeholder={{ color: "secondaryGray.500" }}
        />
        {errorMessage != null && errorMessage != "" && (
          <FormErrorMessage ml={"10px"} mb={mb ? mb : "20px"}>
            {errorMessage}
          </FormErrorMessage>
        )}
      </FormControl>
    </Flex>
  );
}
