import { PATH_APP } from "./paths";
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthProtect from "components/auth/AuthProtect";
import Dashboard from "layouts/admin";

// ----------------------------------------------------------------------

const AppRoutes = {
  path: "*",
  guard: AuthProtect,
  layout: Dashboard,
  currentOrgUserGuardRequired: true,
  routes: [
    // {
    //   exact: true,
    //   path: "/dashboard",
    //   component: lazy(() => import("views/admin/dashboards/default")),
    // },
    // MAIN DASHBOARD
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: "/products-list",
      component: lazy(() => import("views/products/productsList")),
    },
    {
      exact: true,
      path: "/products",
      component: lazy(() => import("views/products/productsList")),
    },
    {
      exact: true,
      path: PATH_APP.product.productDetails + "/:id",
      component: lazy(() => import("views/products/productDetail")),
    },
    {
      exact: true,
      path: PATH_APP.product.productSuppliedDetails + "/:id",
      component: lazy(() =>
        import("views/productSupplied/ProductSuppliedDetail")
      ),
    },

    // settings
    {
      exact: true,
      path: PATH_APP.settings.root,
      component: lazy(() => import("views/settings")),
    },
    // // planning
    // {
    //   exact: true,
    //   path: PATH_APP.planning.root,
    //   component: lazy(() => import("views/planning")),
    // },

    // purchasing
    {
      exact: true,
      path: PATH_APP.purchasing.root,
      component: lazy(() => import("views/purchasing")),
    },
    {
      exact: true,
      path: PATH_APP.purchasing.purchaseOrderDetails + "/:id",
      component: lazy(() =>
        import("views/purchaseOrder/PurchaseOrderDetailView")
      ),
    },
    // receipts
    {
      exact: true,
      path: PATH_APP.receipts.root,
      component: lazy(() => import("views/receipts")),
    },
    // manufacturing
    {
      exact: true,
      path: PATH_APP.manufacturing.root,
      component: lazy(() => import("views/manufacturing/manufacturingOrdersList")),
    },

    {
      exact: true,
      path: PATH_APP.manufacturing.manufacturingOrderDetails + "/:id",
      component: lazy(() => import("views/manufacturing/manufacturingOrderDetails")),
    },

    // Suppliers
    {
      exact: true,
      path: PATH_APP.suppliers.root,
      component: lazy(() => import("views/supplier/supplierList")),
    },
    {
      exact: true,
      path: PATH_APP.suppliers.suppliers,
      component: lazy(() => import("views/supplier/supplierList")),
    },
    {
      exact: true,
      path: PATH_APP.suppliers.supplierDetails + "/:id",
      component: lazy(() => import("views/supplier/supplierDetail")),
    },

    // Customers
    {
      exact: true,
      path: PATH_APP.customers.root,
      component: lazy(() => import("views/customer/customerList")),
    },

    {
      exact: true,
      path: PATH_APP.customers.customerDetails + "/:id",
      component: lazy(() => import("views/customer/customerDetail")),
    },

    // Bill Of materials
    {
      exact: true,
      path: PATH_APP.billOfMaterials.root,
      component: lazy(() =>
        import("views/billOfMaterials/billOfMaterialsList")
      ),
    },
    {
      exact: true,
      path: PATH_APP.billOfMaterials.billOfMaterials,
      component: lazy(() =>
        import("views/billOfMaterials/billOfMaterialsList")
      ),
    },
    {
      exact: true,
      path: PATH_APP.billOfMaterials.billOfMaterialsDetails + "/:id",
      component: lazy(() =>
        import("views/billOfMaterials/billOfMaterialsDetail")
      ),
    },

    // Equipment
    {
      exact: true,
      path: PATH_APP.equipments.root,
      component: lazy(() => import("views/equipment/equipmentList")),
    },
    {
      exact: true,
      path: PATH_APP.equipments.equipments,
      component: lazy(() => import("views/equipment/equipmentList")),
    },
    {
      exact: true,
      path: PATH_APP.equipments.equipmentDetails + "/:id",
      component: lazy(() => import("views/equipment/equipmentDetail")),
    },
    //shipment
    {
      exact: true,
      path: PATH_APP.shipment.root,
      component: lazy(() => import("views/shipment/shipmentOverview")),
    },
    //iventory
    {
      exact: true,
      path: PATH_APP.inventory.root,
      component: lazy(() => import("views/inventory/inventoryOverview")),
    },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/planning" />,
    },
  ],
};

export default AppRoutes;
