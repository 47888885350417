// import NProgress from 'nprogress';
import AppRoutes from "./AppRoutes";
import { PATH_PAGE, PATH_APP } from "./paths";
import HomeRoutes from "./HomeRoutes";
// import LoadingScreen from '~/components/LoadingScreen';
import LoadingScreen from "components/feedback/LoadingScreen";
import GuestProtect from "components/auth/GuestProtect";
import { Switch, Route, Redirect } from "react-router-dom";
import React, { Suspense, Fragment, lazy, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import CurrentOrgUserGuard from "components/auth/CurrentOrgUserGuard";
import AuthProtect from "components/auth/AuthProtect";
// import CalandarDashboard from "layouts/adminCalandarLayout";
import Dashboard from "layouts/admin";

// import { makeStyles } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

// const nprogressStyle = makeStyles(theme => ({
//   '@global': {
//     '#nprogress': {
//       pointerEvents: 'none',
//       '& .bar': {
//         top: 0,
//         left: 0,
//         height: 2,
//         width: '100%',
//         position: 'fixed',
//         zIndex: theme.zIndex.snackbar,
//         backgroundColor: theme.palette.primary.main,
//         boxShadow: `0 0 2px ${theme.palette.primary.main}`,
//       },
//       '& .peg': {
//         right: 0,
//         opacity: 1,
//         width: 100,
//         height: '100%',
//         display: 'block',
//         position: 'absolute',
//         transform: 'rotate(3deg) translate(0px, -4px)',
//         boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
//       },
//     },
//   },
// }));

// function RouteProgress(props) {
//   nprogressStyle();

//   NProgress.configure({
//     speed: 500,
//     showSpinner: false
//   });

//   useEffect(() => {
//     NProgress.done();
//     return () => {
//       NProgress.start();
//     };
//   }, []);

//   return <Route {...props} />;
// }

export const Routes = ({ routes = [] }) => {
  useHistoryChange();
  return renderRoutesInner(routes);
};

function useHistoryChange() {
  const history = useHistory();
  const prevLocation = useRef(history.location);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const sourcePage = prevLocation.current;
      const destinationPage = location;

      // TODO: Find a nice way to store different redirect logic
      const leavingDetailPage = sourcePage?.pathname?.match(/\/purchasing\/purchaseorders\/details\/\d+/);
      const goingToPurchasePage = destinationPage?.pathname === "/purchasing";
      const leftUsingPORedirect = sourcePage?.search?.includes("viewPO=1");
      const shouldLoadPOTab = leavingDetailPage && goingToPurchasePage && leftUsingPORedirect;
      prevLocation.current = location;

      if (shouldLoadPOTab) {
        history.push({
          pathname:
            PATH_APP.purchasing.root,
          search: "viewPO=1"
        })
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);
}


const renderRoutesInner = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const Guard = route.guard || Fragment;
        const CurrentOrgUserGuardRequired = route.currentOrgUserGuardRequired
          ? CurrentOrgUserGuard
          : Fragment;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <CurrentOrgUserGuardRequired>
                  <Layout>
                    {route.routes ? (
                      renderRoutesInner(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </CurrentOrgUserGuardRequired>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  // Others Routes
  {
    exact: true,
    guard: GuestProtect,
    path: "/login",
    component: lazy(() => import("views/auth/signIn/SignInDefault")),
  },
  // {
  //   exact: true,
  //   path: PATH_PAGE.auth.loginUnprotected,
  //   component: lazy(() => import('~/views/auth/LoginView')),
  // },
  // {
  //   exact: true,
  //   guard: GuestProtect,
  //   path: PATH_PAGE.auth.register,
  //   component: lazy(() => import('~/views/auth/RegisterView')),
  // },
  // {
  //   exact: true,
  //   path: PATH_PAGE.auth.registerUnprotected,
  //   component: lazy(() => import('~/views/auth/RegisterView')),
  // },
  // {
  //   exact: true,
  //   path: PATH_PAGE.auth.resetPassword,
  //   component: lazy(() => import('~/views/auth/ResetPasswordView')),
  // },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("views/errors/Page404View")),
  },
  {
    exact: true,
    path: "/500",
    component: lazy(() => import("views/errors/Page500View")),
  },
  // {
  //   exact: true,
  //   path: '/loading',
  //   component: lazy(() => import('src/components/feedback/LoadingScreen'))
  // },
  // {
  //   exact: true,
  //   path: PATH_PAGE.comingSoon,
  //   component: lazy(() => import('~/views/pages/ComingSoonView')),
  // },
  // {
  //   exact: true,
  //   path: PATH_PAGE.maintenance,
  //   component: lazy(() => import('~/views/pages/MaintenanceView')),
  // },
  {
    exact: true,
    path: PATH_PAGE.auth.root,
    component: () => <Redirect to={PATH_PAGE.auth.login} />,
  },
  {
    exact: true,
    guard: AuthProtect,
    currentOrgUserGuardRequired: true,
    path: PATH_APP.org.setup,
    component: lazy(() => import("views/org/OrgSetup")),
  },
  // planning - hacked
  {
    exact: true,
    path: PATH_APP.planning.root,
    guard: AuthProtect,
    layout: Dashboard,
    currentOrgUserGuardRequired: true,
    component: lazy(() => import("views/planning")),
  },

  // App Routes
  AppRoutes,

  // Home Routes
  HomeRoutes,
];

export default routes;
