import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firebaseReducer } from 'react-redux-firebase';
import orgUserReducer from './slices/orgUser'
import productSuppliedRefetchReducer from './slices/productSuppliedRefetch';
// import darkModeReducer from './slices/dark-mode';
// import productReducer from './slices/product';
// import userReducer from './slices/user';
// import notificationsReducer from './slices/notifications';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  version: 1,
  whitelist: ['theme']
};

// const productPersistConfig = {
//   key: 'product',
//   storage: storage,
//   keyPrefix: 'redux-',
//   blacklist: ['isLoading', 'error', 'products', 'product', 'filters']
// };

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  orgUser: orgUserReducer,
  productSuppliedRefetch: productSuppliedRefetchReducer,
  // theme: darkModeReducer,
  // product: persistReducer(productPersistConfig, productReducer),
  // user: userReducer,
  // notifications: notificationsReducer
});

export { rootPersistConfig, rootReducer };
