import { map } from "lodash";
import client from "common/graphql/MyApolloClient";
import { CURRENT_ORG_USER } from "common/graphql/orgUser";
import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  errors: null,
  currentOrgUser: null,
};

const slice = createSlice({
  name: "orgUser",
  initialState,
  reducers: {
    setOrgUser(state, action) {
      state.isLoading = false;
      state.error = null;
      state.currentOrgUser = action.payload;
    },
    setFailedOrgUserLoad(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.currentOrgUser = null;
    },

    updateOrgForUser(state, action) {
      state.isLoading = false;
      state.error = null;

      state.currentOrgUser = {
        ...state.currentOrgUser,
        organization: action.payload,
      };
    },

    logOutUser(state) {
      state.isLoading = true;
      state.error = null;
      state.currentOrgUser = null;
    },
  },
});

// Reducer
export default slice.reducer;
export const { logOutUser, updateOrgForUser } = slice.actions;

export function getCurrentOrgUser(name) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    console.log("getCurrentOrgUser");
    client
      .query({
        query: CURRENT_ORG_USER,
        variables: {},
        fetchPolicy: "network-only",
      })
      .then(({ errors, data }) => {
        if (errors) {
          dispatch(
            slice.actions.setFailedOrgUserLoad("Failed to load org user")
          );
          console.log("Errors during GET_CONTACT_US_FLOWS", errors);
          return;
        }

        console.log("currentOrganizationUserDATA", data);
        if (
          data &&
          data.currentOrganizationUser &&
          data.currentOrganizationUser.id
        ) {
          dispatch(slice.actions.setOrgUser(data.currentOrganizationUser));
        } else {
          dispatch(
            slice.actions.setFailedOrgUserLoad("Failed to load org user")
          );
        }
      });
  };
}
