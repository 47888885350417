import { gql } from "@apollo/client";

export const CURRENT_ORG_USER = gql`
  query currentOrganizationUser {
    currentOrganizationUser {
      id
      email
      role
      firstName
      lastName
      organization {
        id
        name
        taxId
        addressOne
        addressTwo
        city
        stateProvince
        postalCode
        country
        contactName
        contactPhoneNumber
        contactEmail
        website
      }
    }
  }
`;

